import React from "react"
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import Layout from "../components/App/Layout"
import { Container, Row, Col } from "react-bootstrap"
import PageBanner from "../components/Common/PageBanner"
import { graphql, Link } from "gatsby"
import Seo from "../components/App/Seo"

const Error = ({ data }) => {

  return (
    <Layout>
      <Seo title={"Offene Stellen / Jobs bei LOGENTIS"}
           description={"Übersicht unserer ausgeschriebenen Positionen. " +
           "Auch full-remote möglich. Bewirb Dich jetzt!"}
           image={"/images/og/jobs-logentis-og.png"}
      />
      <Navbar />
      <PageBanner
        pageTitle="Jobs bei LOGENTIS"
        homePageText="Home"
        homePageUrl="/"
        activePageText="Jobs"
        makeH1={true}
      />

      <section className="contact-area pb-100">
        <Container>
          <Row>
            {data.allMarkdownRemark.edges.map(({ node: job }) => (
              <Col key={job.id} xs={12} md={6}>
                <div className="jumbotron h-100 d-flex flex-column">
                  <div style={{flexGrow: 1}}>
                    <h2 className="display-5"><Link to={"/jobs" + job.fields.slug}>{job.frontmatter.jobtitel}</Link></h2>
                    <div>
                      <ul>
                        <li>Ort: {job.frontmatter.ort}</li>
                        <li>Zeitraum: {job.frontmatter.zeitraum}</li>
                      </ul>
                    </div>
                    <p className="lead">
                      {job.frontmatter.einleitung}
                    </p>
                  </div>
                  <div style={{flexGrow: 9}} className={"d-flex flex-column justify-content-end"}>
                    <div><hr className="my-4" /></div>
                    <p>Für weitere Details klicken Sie einfach auf den Button. </p>
                    <p className="lead">
                      <Link className="btn btn-primary btn-lg" to={"/jobs" + job.fields.slug}
                            role="button">Jobdetails</Link>
                    </p>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
      <Footer />
    </Layout>
  )
}

export default Error


export const query = graphql`
  query jobPageQuery {
    allMarkdownRemark(filter: {frontmatter: {active: {eq: true}}, fields: {collection: {eq: "jobs"}}}) {
      edges {
        node {
          id
          frontmatter {
            jobtitel
            einleitung
            zeitraum
            ort
          }
          fields {
            collection
            slug
          }
        }
      }
    }
  }
`
